
import { defineComponent } from 'vue';
import {
  useScriptMixin, UseScriptMixinData
} from '@/toolkit/mixins/useScript';
import {
  useResize,
  Data as UseResizeData,
  WINDOW_SIZE
} from '@/toolkit/mixins/useResize';

type Data = UseScriptMixinData & UseResizeData & {
  showOnSmall: boolean;
};

export default defineComponent({
  name: 'pricePerUnit',
  mixins: [
    useScriptMixin('https://www.tiktok.com/embed.js'),
    useResize()
  ],
  data(): Data {
    return {
      scriptLoaded: false,
      scriptError: false,
      script: '',
      windowWidth: window.innerWidth,
      showOnSmall: window.innerWidth > WINDOW_SIZE.small,
    };
  },
  watch: {
    windowWidth() {
      this.showOnSmall = this.windowWidth > WINDOW_SIZE.small;
    },
  },
});
